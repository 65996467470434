import React from "react"
import styles from "./style.module.less"
import DropDown from "./DropDown"

import Slide from "react-reveal/Reveal"
import Reveal from "react-reveal/Reveal"

const CrewStory = () => (
  <div className={styles.crewStory}>
    <div>
      <br />
      <Reveal>
        <h2>
          Real people
          <br />
          make real work.
        </h2>
      </Reveal>
      <Slide bottom>
        <h3>Together, we make a meaningful Thesis.</h3>
      </Slide>
      <br />
      <br />
      <DropDown />
    </div>
  </div>
)

export default CrewStory
