import React from "react"
import styles from "./style.module.less"

import Reveal from "react-reveal/Reveal"

import black from "./images/black/oval.png"
import latinx from "./images/latinx/oval.png"
import asian from "./images/asian/oval.png"
import more from "./images/more/oval.png"
import white from "./images/white/oval.png"

const CrewMid = () => (
  <div className={styles.crewMid}>
    <Reveal>
      <p className={styles.par}>
        It’s a crucial way of thinking, of working, and of moving through the
        world. At Thesis, we prioritize diversity, which isn’t just about race,
        ethnicity, sexuality, or gender, but about celebrating each trait that
        makes us different from one another. We know that the more varied our
        backgrounds, experiences, and skills, the better our work will be, and
        the wider our audience.
        <br />
        <br />
        We also know that diversity doesn’t work without real equity and
        inclusion. That means fair treatment, access, and opportunity to
        everyone—a culture in which people from marginalized groups feel just as
        welcomed, valued, and supported as people from advantaged groups.
        <br />
        <br />
        You’ll see these values reflected in our hiring practices, growth plans,
        and workplace programs—because our agency, our work, and our society are
        better when different perspectives come together.
      </p>
    </Reveal>
    <div className={styles.list}>
      <Reveal>
        <div className={styles.item}>
          <img src={black} className={styles.race}></img>
          <div className={styles.name}>BLACK/AFRICAN</div>
        </div>
        <div className={styles.item}>
          <img src={latinx} className={styles.race}></img>
          <div className={styles.name}>LATINX</div>
        </div>
        <div className={styles.item}>
          <img src={asian} className={styles.race}></img>
          <div className={styles.name}>ASIAN</div>
        </div>
        <div className={styles.item}>
          <img src={more} className={styles.race}></img>
          <div className={styles.name}>2 OR MORE RACES</div>
        </div>
        <div className={styles.item}>
          <img src={white} className={styles.race}></img>
          <div className={styles.name}>WHITE</div>
        </div>
      </Reveal>
    </div>
  </div>
)

export default CrewMid
