import React from "react"
import styles from "./style.module.less"

import Slide from "react-reveal/Reveal"

import topImage from "./images/Desktop/topD/graph1desktop.png"
import botImage from "./images/Desktop/botD/graph2desktop.png"

const CrewStats = () => (
  <div className={styles.CrewStats}>
    <div className={styles.statCard}>
      <div className={styles.par}>
        <Slide bottom>
          <h1>
            PEOPLE&nbsp;OF&nbsp;COLOR AT&nbsp;THESIS
            <span> (BEFORE&nbsp;INTENTIONALITY)</span>
          </h1>
          <h2>12%</h2>
        </Slide>
      </div>
      <img src={topImage}></img>
    </div>

    <div className={styles.statCard}>
      <div className={styles.par}>
        <Slide bottom>
          <h1 className={styles.h1}>
            PEOPLE&nbsp;OF&nbsp;COLOR AT&nbsp;THESIS
            <span> (4&nbsp;YEARS&nbsp;LATER)</span>
          </h1>
          <h2 className={styles.h2}>33%</h2>
        </Slide>
      </div>
      <img src={botImage} className={styles.imageB}></img>
    </div>
  </div>
)

export default CrewStats
