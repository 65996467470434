import React from "react"
import ReactCardFlip from "react-card-flip"
import { Lazy } from "react-lazy"

import { smartCrop } from "util/imgResize"

import "./img.less"
import styles from "./style.module.less"

import { employees } from "content/employee/people.json"

class DropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      isFlipped: new Array(employees.length).fill(false),
      value: "CHOOSE",
    }
    this.buttonRef = React.createRef()
    this.menuRef = React.createRef()
  }

  handleClick = index => event => {
    console.log("picture ", index)
    console.log("flipped", this.state.isFlipped.length)
    event.preventDefault()
    this.setState(state => {
      const isFlipped = state.isFlipped.map((item, j) => {
        if (j === index) {
          return !item
        } else {
          return item
        }
      })
      return {
        isFlipped,
      }
    })
  }

  toggleState = value => event => {
    let { name } = event.target
    this.setState({
      ...this.state,
      value: value,
      isFlipped: new Array(employees.length).fill(false),
    })
  }

  toggleMenu() {
    this.setState(prevState => ({ isActive: !prevState.isActive }))
  }

  openMenu() {
    this.setState({ isActive: true })
  }

  closeMenu() {
    this.setState({ isActive: false })
  }

  handleBlur() {
    const self = this
    self.blurTimer = setTimeout(function() {
      const buttonNode = self.buttonRef.current
      const activeEl = buttonNode.ownerDocument.activeElement
      if (buttonNode && activeEl === buttonNode) return self.closeMenu()
      const menuNode = self.menuRef.current
      if (menuNode === activeEl) {
        return
      }
      if (menuNode && menuNode.contains(activeEl)) return
      if (self.state.isActive) self.closeMenu()
    }, 0)
  }

  render() {
    let teamName = this.state.value
    let teamAry = employees.filter(e => e.active)
    let isShowAll =
      teamName === "CHOOSE" || teamName === "All" || teamName === "all"
    if (!isShowAll) {
      teamAry = employees.filter(row => row.team == teamName)
    }
    return (
      <div className={styles.crewList}>
        <div
          className={styles.dropdown}
          onClick={() => this.toggleMenu()}
          onFocus={() => this.openMenu()}
          onBlur={() => this.handleBlur()}
        >
          <div className={styles.toggle} ref={this.buttonRef}>
            <div> {this.state.value}</div>
            <div className={styles.rotate}>+</div>
          </div>
          {this.state.isActive && (
            <ul className={styles.menu} ref={this.menuRef}>
              <li className={styles.menuItem} onClick={this.toggleState("all")}>
                ALL
              </li>
              <li
                className={styles.menuItem}
                onClick={this.toggleState("design")}
              >
                DESIGN
              </li>
              <li
                className={styles.menuItem}
                onClick={this.toggleState("writing")}
              >
                WRITING
              </li>
              <li
                className={styles.menuItem}
                onClick={this.toggleState("strategy")}
              >
                STRATEGY
              </li>
              <li
                className={styles.menuItem}
                onClick={this.toggleState("technology")}
              >
                TECHNOLOGY
              </li>
              <li
                className={styles.menuItem}
                onClick={this.toggleState("administration")}
              >
                ADMINISTRATION
              </li>
              <li
                className={styles.menuItem}
                onClick={this.toggleState("partner services")}
              >
                PARTNER SERVICES
              </li>
              <li
                className={styles.menuItem}
                onClick={this.toggleState("executive")}
              >
                EXECUTIVE LEADERSHIP
              </li>
            </ul>
          )}
        </div>
        <div className={styles.CrewList}>
          <div className={styles.CrewCard}>
            <div className={styles.cards}>
              {teamAry.map((s, i) => (
                <div key={i}>
                  <ReactCardFlip
                    isFlipped={this.state.isFlipped[i]}
                    flipSpeedFrontToBack={1.0}
                    flipSpeedBackToFront={1.0}
                    flipDirection="vertical"
                    cardZIndex
                  >
                    <div
                      key="front"
                      style={this.props.card}
                      onClick={this.handleClick(i)}
                    >
                      <Lazy component="div" className={styles.ImageContainer}>
                        <img
                          className="lazy"
                          src={smartCrop(s.photo, 400, 468, "jpeg")}
                          onLoad={e => e.target.classList.add("loaded")}
                          alt={`${s.firstName} ${s.lastName} - ${s.position} | Thesis Agency`}
                        />
                      </Lazy>
                    </div>

                    <div
                      key="back"
                      style={this.props.card}
                      onClick={this.handleClick(i)}
                    >
                      <div className={styles.TextContainer}>
                        <p>
                          <span className={styles.name}>
                            {s.firstName} {s.lastName}
                          </span>
                          <span className={styles.position}>{s.position}</span>
                        </p>
                      </div>
                    </div>
                  </ReactCardFlip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DropDown
