import React from "react"
import PropTypes from "prop-types"

import { smartCrop, fit } from "util/imgResize"

const getSmall = x => Math.round(x / 3)
const getMed = x => getSmall(x) * 2

const widthDefault = 1024
const heightDefault = 768

const ResponsiveImage = ({ crop = true, width, height, src, ...rest }) => {
  // fail gracefully with no image or no width
  if (!src || !width) return null

  // cropping requires width and height.
  if (crop && height) {
    return <CroppedImage src={src} width={width} height={height} {...rest} />
  } else {
    return <FitImage src={src} width={width} {...rest} />
  }
}

ResponsiveImage.propTypes = {
  crop: PropTypes.bool,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
}

export const CroppedImage = ({ src, width, height, ...rest }) => {
  const w = width || widthDefault
  const h = height || heightDefault
  const [sW, sH, mW, mH] = [getSmall(w), getSmall(h), getMed(w), getMed(h)]
  const lgSrc = smartCrop(src, w, h)
  const smallSrc = smartCrop(src, sW, sH)
  const medSrc = smartCrop(src, mW, mH)
  const srcSet = `${lgSrc} ${width}w, ${medSrc} ${mW}w, ${smallSrc} ${sW}w,`
  return <img src={src} srcSet={srcSet} {...rest} />
}

export const FitImage = ({ src, width, ...rest }) => {
  const w = width || widthDefault
  const [sW, mW] = [getSmall(w), getMed(w)]
  const lgSrc = fit(src, w)
  const smallSrc = fit(src, sW)
  const medSrc = fit(src, mW)
  const srcSet = `${lgSrc} ${w}w, ${medSrc} ${mW}w, ${smallSrc} ${sW}w,`
  return <img src={src} srcSet={srcSet} {...rest} />
}

export default ResponsiveImage
