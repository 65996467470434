import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import CrewTop from "../components/Crew/CrewTop"
import CrewMid from "../components/Crew/CrewMid"
import CrewStats from "../components/Crew/CrewStats"
import CrewStory from "../components/Crew/CrewStory"

const People = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="Thesis | Real people make real work"
      description="We believe our agency, our work, and our society are better when diverse perspectives come together. Learn more about the real people of Thesis."
    />
    <CrewTop></CrewTop>
    <CrewMid></CrewMid>
    <CrewStats></CrewStats>
    <CrewStory></CrewStory>
  </Layout>
)

export default People
