import React from "react"
import Img from "../../ResponsiveImg"

import styles from "./style.module.less"

import Slide from "react-reveal/Reveal"
import Reveal from "react-reveal/Reveal"

const CrewTop = () => (
  <div className={styles.CrewTop}>
    <Reveal>
      <section className={styles.header}>
        <Slide bottom>
          <h2>Workplace equity is not a single checkbox.</h2>
        </Slide>
      </section>
      <CrewImage />
    </Reveal>
  </div>
)
export default CrewTop

const CrewImage = () => (
  <Img src="/images/crew-equity-desktop@3x.jpg" width={1000} />
)
